import React from 'react'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import Bugsnag from 'utils/helpers/bugsnagHelper'

const maskError = (error: JSON, keys: string[]) => {
  if (!error) {
    return
  }
  for (const key in error) {
    if (keys.indexOf(key) !== -1) {
      error[key] = `[${key}]`
    }
    const value = error[key]

    if (!value) {
      continue
    }

    if (typeof value === 'string') {
      try {
        const obj = JSON.parse(value)

        maskError(obj, keys)
        error[key] = JSON.stringify(obj)
      } catch {}
    } else if (
      (value.length > 0 && typeof value === 'object') ||
      typeof value === 'object'
    ) {
      maskError(value, keys)
    }
  }
}

Bugsnag.start({
  appVersion: process.env.VERSION,
  apiKey: process.env.BUG_SNAG_API_KEY || '250ba777c27590d62985a812797a4e8d',
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.REACT_APP_STATIC_ENV,
  onError: event => {
    try {
      const message = event.errors[0].errorMessage
      const error = JSON.parse(message)

      maskError(error, ['email', 'token'])

      event.errors[0].errorMessage = JSON.stringify(error)
    } catch {}
  },
})

interface Props {
  children: React.ReactElement
}

function EmptyWrap(props: Props) {
  return props.children
}

export const ErrorBoundary =
  Bugsnag.getPlugin('react')?.createErrorBoundary(React) || EmptyWrap
